<template>
  <div>
    <div class="flex flex-row">
      <div>
        <h1 class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2">
          <span class="text-grey-medium-2 font-normal">{{
            `Companies / ${currentCompany.name} / Subscription and Invites /`
          }}</span>
          Subscription Details
        </h1>
      </div>
      <div class="ml-auto" v-if="subscriptionInfo">
        <div class="flex flex-row space-x-2" v-if="subscriptionInfo.subscriptionStatus === 0">
          <Button text="Activate" size="medium" :disabled="!hasSelectedProducts" @click.native="
            openActivateodal()
            " />
        </div>
        <div class="flex flex-row space-x-2" v-if="subscriptionInfo.subscriptionStatus === 1">
          <Button text="Deactivate" size="medium" type="danger" @click.native="openDeactivateModal()" />
          <Button text="Suspend" size="medium" type="primary-white" @click.native="openSuspendModal()" />
          <Button text="Update" size="medium" type="primary" :disabled="!hasSelectedProducts || !hasChanges"
            @click.native="
              openUpdateModal()
              " />
        </div>
        <div class="flex flex-row space-x-2" v-if="subscriptionInfo.subscriptionStatus === 3">
          <Button text="Deactivate" size="medium" type="danger" @click.native="openDeactivateModal()" />
          <Button text="Resume" size="medium" type="primary" :disabled="!hasSelectedProducts"
            @click.native="openResumeModal()" />
        </div>
        <div class="flex flex-row space-x-2" v-if="
          subscriptionInfo.subscriptionStatus === 4 ||
          subscriptionInfo.subscriptionStatus === 2
        ">
          <Button text="Renew" size="medium" type="primary" :disabled="!hasSelectedProducts"
            @click.native="openRenewModal()" />
        </div>
      </div>
    </div>
    <div>
      <SubscriptionDetailsForm />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Button from "@/components/Button/Button.vue";
import SubscriptionDetailsForm from "../components/SubscriptionDetailsForm/index.vue";

export default {
  name: "SubscriptionDetails",
  components: {
    Button,
    SubscriptionDetailsForm,
  },
  data: () => ({
    initialState: null,
  }),
  methods: {
    ...mapActions({
      getSubscriptionDetails: "subscriptions/getSubscriptionDetails",
      updateSubscriptionStatus: "subscriptions/updateSubscriptionStatus",
      updateSubscription: "subscriptions/updateSubscription",
      openModal: "modals/openModal",
    }),
    openDeactivateModal() {
      this.openModal({
        modal: "confirmSubscriptionUpdateModal", props: {
          title: "Confirm subscription deactivation",
          content: `Are you sure you want to deactivate the subscription for ${this.currentCompany.name}?`,
          confirmText: 'Deactivate',
          buttonType: 'danger',
          acceptFn: async () => this.updateSubscriptionStatus({
            organizationId: this.currentCompany.id,
            action: 2,
          })
        }
      });
    },
    openRenewModal() {
      this.openModal({
        modal: "confirmSubscriptionUpdateModal", props: {
          title: "Confirm subscription renew",
          content: `Are you sure you want to renew the subscription for ${this.currentCompany.name}?`,
          confirmText: 'Renew',
          buttonType: 'primary',
          acceptFn: async () => this.updateSubscription({
            organizationId: this.currentCompany.id,
            action: 1,
          })
        }
      });
    },
    openSuspendModal() {
      this.openModal({
        modal: "confirmSubscriptionUpdateModal", props: {
          title: "Confirm subscription suspension",
          content: `Are you sure you want to suspend the subscription for ${this.currentCompany.name}?`,
          confirmText: 'Suspend',
          buttonType: 'danger',
          acceptFn: async () => this.updateSubscriptionStatus({
            organizationId: this.currentCompany.id,
            action: 3,
          })
        }
      });
    },
    openResumeModal() {
      this.openModal({
        modal: "confirmSubscriptionUpdateModal", props: {
          title: "Confirm subscription resumption",
          content: `Are you sure you want to resume the subscription for ${this.currentCompany.name}?`,
          confirmText: 'Resume',
          buttonType: 'primary',
          acceptFn: async () => this.updateSubscription({
            organizationId: this.currentCompany.id,
            action: 1,
          })
        }
      });
    },
    openUpdateModal() {
      this.openModal({
        modal: "confirmSubscriptionUpdateModal", props: {
          title: "Confirm subscription update",
          content: `Are you sure you want to update the subscription for ${this.currentCompany.name}?`,
          confirmText: 'Update',
          buttonType: 'primary',
          acceptFn: async () => this.updateSubscription({
            organizationId: this.currentCompany.id,
            action: 4,
          })
        }
      });
    },
    openActivateodal() {
      this.openModal({
        modal: "confirmSubscriptionUpdateModal", props: {
          title: "Confirm subscription activation",
          content: `Are you sure you want to activate the subscription for ${this.currentCompany.name}? All the company admins will be notified of the activation.`,
          confirmText: 'Update',
          buttonType: 'primary',
          acceptFn: async () => this.updateSubscription({
            organizationId: this.currentCompany.id,
            action: 1,
          })
        }
      });
    }
  },
  computed: {
    ...mapState({
      subscriptionInfo: (state) => state.subscriptions.subscriptionDetails,
      formDetails: (state) => state.subscriptions.form,
      currentCompany: (state) => state.companies.currentCompany,
    }),
    hasSelectedProducts() {
      return (
        this.formDetails.selectedProducts.filter(
          (product) => product.isSelected
        ).length > 0
      );
    },
    hasChanges() {
      const form = this.formDetails;
      const state = this.initialState;
      form.reminders.sort();
      state?.reminders.sort();
      if (form.lead !== state?.lead) return true;
      if (form.pastResults !== state?.pastResults) return true;
      if (form.startDate !== state?.startDate) return true;
      if (form.endDate !== state?.endDate) return true;
      if (
        form.reminders.length !== state?.reminders.length &&
        !(JSON.stringify(form.reminders) === JSON.stringify(state?.reminders))
      )
        return true;
      if (JSON.stringify(form) !== JSON.stringify(state)) return true;
      return false;
    },
  },
  async mounted() {
    await this.getSubscriptionDetails(this.$route.params.companyId);
    this.initialState = JSON.parse(JSON.stringify(this.formDetails));
  },
};
</script>
